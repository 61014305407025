import React, { useEffect, useRef } from "react";
import * as classes from "./meetingform.module.scss";

const Form = () => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log('Received message:', event.data);
      if (event.data.action === 'changeStyle') {
        const iframeContentWindow = iframeRef.current.contentWindow;
        iframeContentWindow.postMessage({ action: 'changeStyle' }, 'https://mereos.pipedrive.com');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <div className={`${classes.container} w-full px-12 py-8 rounded-lg border-box`}>
      <iframe 
        ref={iframeRef}
        src="https://mereos.pipedrive.com/scheduler/rR5P1rf2/request-a-meeting-with-un-commercial-mereos"  
        height="600px" 
        width="100%" 
        style={{ maxWidth: "500px", borderRadius: '10px' }} 
        allowFullScreen
      />
    </div>
  );
};

export default Form;
